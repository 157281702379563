var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "el-container",
        { staticStyle: { height: "100%" } },
        [
          _c("el-header", [_c("h2", [_vm._v("ai助手")])]),
          _c(
            "el-main",
            { staticStyle: { padding: "10px", "overflow-y": "auto" } },
            [
              _c(
                "div",
                { staticClass: "messages-container" },
                _vm._l(_vm.messages, function(message, index) {
                  return _c(
                    "el-row",
                    { key: index },
                    [
                      _c(
                        "el-col",
                        {
                          attrs: {
                            span: message.sender === "user" ? 12 : 12,
                            offset: message.sender === "user" ? 12 : 0
                          }
                        },
                        [
                          _c(
                            "el-card",
                            {
                              staticClass: "message-card",
                              class:
                                message.sender === "user"
                                  ? "user-message"
                                  : "bot-message"
                            },
                            [_c("p", [_vm._v(_vm._s(message.text))])]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ]
          ),
          _c(
            "el-footer",
            { staticClass: "footer" },
            [
              _c(
                "el-input",
                {
                  staticClass: "input_send",
                  attrs: {
                    placeholder: "输入消息...",
                    clearable: "true",
                    size: "180"
                  },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.sendMessage($event)
                    }
                  },
                  model: {
                    value: _vm.inputMessage,
                    callback: function($$v) {
                      _vm.inputMessage = $$v
                    },
                    expression: "inputMessage"
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { slot: "append" },
                      on: { click: _vm.sendMessage },
                      slot: "append"
                    },
                    [_vm._v("发送")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }