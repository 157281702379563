<template>
    <div id="app" class="app_div">
        <chat-box></chat-box>
    </div>
</template>

<script>
import ChatBox from './ChatBox.vue';

export default {
    name: 'App',
    components: {
        ChatBox
    }
};
</script>

<style>
.app_div {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    height: 800px;
}
</style>