<template>
    <div id="app">
        <el-container style="height: 100%;">
            <el-header>
                <h2>ai助手</h2>
            </el-header>
            <el-main style="padding: 10px; overflow-y: auto;">
                <div class="messages-container">
                    <el-row v-for="(message, index) in messages" :key="index">
                        <el-col :span="message.sender === 'user' ? 12 : 12"
                            :offset="message.sender === 'user' ? 12 : 0">
                            <el-card :class="message.sender === 'user' ? 'user-message' : 'bot-message'"
                                class="message-card">
                                <p>{{ message.text }}</p>
                            </el-card>
                        </el-col>
                    </el-row>
                </div>
            </el-main>
            <el-footer class="footer">
                <el-input 
                v-model="inputMessage" 
                placeholder="输入消息..." 
                @keyup.enter="sendMessage" 
                clearable=true
                size="180"
                class="input_send" >
                    <el-button slot="append" @click="sendMessage" >发送</el-button>
                </el-input>
            </el-footer>
        </el-container>
    </div>
</template>

<script>
export default {
    data() {
        return {
            inputMessage: '',
            messages: [],
            dictionary: {
                "你好": "你好！有什么我可以帮您的吗？",
                "天气": "今天天气不错哦！",
                "再见": "再见！期待您的下次光临！",
            }
        };
    },
    methods: {
        sendMessage() {
            const message = this.inputMessage.trim();
            if (message) {
                this.messages.push({ text: `${message}`, sender: 'user' });
                this.getResponse(message);
                this.inputMessage = '';
            }
        },
        getResponse(message) {
            const response = this.dictionary[message] || "未学习相关知识";
            this.messages.push({ text: `机器人: ${response}`, sender: 'bot' });
        }
    }
};
</script>

<style>
html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: Arial, sans-serif;
}

.messages-container {
    max-height: 70%;
    overflow-y: auto;
}

.message-card {
    margin: 5px 0;
    padding: 10px;
    border-radius: 5px;
    word-wrap: break-word;
    /* 自动换行 */
}

.user-message {
    background-color: #e6f7ff;
    /* 用户消息背景色 */
}

.bot-message {
    background-color: #f0f0f0;
    /* 机器人消息背景色 */
}

.footer {
    width: 100%;
    background: white;
    padding: 10px;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: 0;
    height: 100%;
}

.input_send {
    width: 70%;
    margin-right: 10%;
    font-size: 20px; 
}

.button{
    font-size: 20px;
}
</style>